<div class="navigation">
    <div class="navigation_logo">
        <img src="https://cmj-site.s3.us-east-2.amazonaws.com/CMJ-Logo.png" alt="">
    </div>
    <div class="navigation_links">
        <span>Home</span>
        <span>About</span>
        <span>Services</span>
        <span>Portfolio</span>
        <span>Contact</span>
    </div>
</div>
