import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app/app.service';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'cmj-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public ourImgs = [];
  public logoImg: any;
  public pageData: any;
  public serviceSelection: any;
  cardsContainer: any = document.querySelector('.cards');
  cardsContainerInner = document.querySelector('.cards__inner');
  cards = Array.from(document.querySelectorAll('.card'));
  overlay: any = document.querySelector('.overlay');

  serviceFormGroup = this._formBuilder.group({
    service: ['', Validators.required],
  });

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.pageData = this.appService.checkCookieAndSetStorage('main');
    this.cards.forEach(this.initOverlayCard);

    this.appService.serviceSelection.subscribe((res) => {
      this.serviceSelection = res;
    });
  }

  public shuffle(arr: string[]): string[] {
    return arr.sort((a, b) => 0.5 - Math.random());
  }

  applyOverlayMask = (e: any) => {
    const overlayEl = e.currentTarget;
    const x = e.pageX - this.cardsContainer.offsetLeft;
    const y = e.pageY - this.cardsContainer.offsetTop;

    overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
  };

  createOverlayCta = (overlayCard: any, ctaEl: any) => {
    const overlayCta: any = document.createElement('div');
    overlayCta.classList.add('cta');
    overlayCta.textContent = ctaEl.textContent;
    overlayCta.setAttribute('aria-hidden', true);
    overlayCard.append(overlayCta);
  };

  observer = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const cardIndex = this.cards.indexOf(entry.target);
      let width = entry.borderBoxSize[0].inlineSize;
      let height = entry.borderBoxSize[0].blockSize;

      if (cardIndex >= 0) {
        this.overlay.children[cardIndex].style.width = `${width}px`;
        this.overlay.children[cardIndex].style.height = `${height}px`;
      }
    });
  });

  initOverlayCard = (cardEl: any) => {
    const overlayCard = document.createElement('div');
    overlayCard.classList.add('card');
    this.createOverlayCta(overlayCard, cardEl.lastElementChild);
    this.overlay.append(overlayCard);
    this.observer.observe(cardEl);
  };
}
