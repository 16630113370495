import {Component, Input} from '@angular/core';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreakpointObserver} from '@angular/cdk/layout';
import {StepperOrientation, MatStepperModule} from '@angular/material/stepper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { AppService } from 'src/app/services/app/app.service';


/**
 * @title Stepper responsive
 */
@Component({
  selector: 'cmj-stepper',
  templateUrl: 'stepper.component.html',
  styleUrls: ['stepper.component.scss'],
})
export class StepperComponent {
  @Input() public followUsVideo: any;

  customerFormGroup = this._formBuilder.group({
    customerName: ['', Validators.required],
    customerEmail: ['', Validators.required],
    customerPhone: ['', Validators.required],
    customerWebsite: [''],
  });
  projectFormGroup = this._formBuilder.group({
    projectDetails: ['', Validators.required],
  });

  stepperOrientation: Observable<StepperOrientation>;

  constructor(
    private appService: AppService,
    private _formBuilder: FormBuilder,
    breakpointObserver: BreakpointObserver,
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  sendEmail() {
    const newCustomerRequest = {
      customerName: this.customerFormGroup.value.customerName,
      customerEmail: this.customerFormGroup.value.customerEmail,
      customerPhone: this.customerFormGroup.value.customerPhone,
      customerWebsite: this.customerFormGroup.value.customerWebsite,
      projectDetails: this.projectFormGroup.value.projectDetails,
      selectedService: this.appService.getLocalStorage('serviceSelected'),
    };

    this.appService.send('api/email', newCustomerRequest)
    .subscribe((res: any) => {
      if (res.status === 200) {
        this.customerFormGroup.reset();
        this.projectFormGroup.reset();
        this.appService.setLocalStorage('serviceSelected', '');
        this.appService.serviceSelection.next('');
        this.appService.showBanner.next({show: true, msg: 'Email sent successfully 🎉'});
      }
    }, (err) => {
      console.log('send email error', err);
      this.appService.showBanner.next({show: true, msg: 'Email not sent try again!! 😢'});
    });
  }
}
