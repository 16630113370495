import { Component } from '@angular/core';

@Component({
  selector: 'cmj-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

}
