<mat-stepper class="cmj-stepper" [orientation]="(stepperOrientation | async)!">
  <mat-step [stepControl]="customerFormGroup" label="Customer Info">
    <form [formGroup]="customerFormGroup">
      <mat-form-field>
        <mat-label>Name:</mat-label>
        <input
          matInput
          placeholder="Last name, First name"
          formControlName="customerName"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email:</mat-label>
        <input
          matInput
          placeholder="test@gmail.com"
          formControlName="customerEmail"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone:</mat-label>
        <input
          matInput
          placeholder="313-555-5555"
          formControlName="customerPhone"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>What Is Your Current Website URL?</mat-label>
        <input
          matInput
          formControlName="customerWebsite"
          placeholder="www.example.com or N/A"
          required
        />
      </mat-form-field>
      <div>
        <button mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="projectFormGroup" label="Project Details">
    <form [formGroup]="projectFormGroup">
      <mat-form-field>
        <mat-label>How may we help you?</mat-label>
        <textarea
          matInput
          formControlName="projectDetails"
          placeholder="Ex. I would like to get a new website..."
          required
        >
        ></textarea
        >
      </mat-form-field>

      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="sendEmail()">Send Email</button>
      </div>

      <!-- <div class="follow-us-video">
        <video
        autoplay="true"
        [muted]="true"
        [loop]="true"
        [src]="followUsVideo"
      ></video>
      </div> -->
    </form>
  </mat-step>
</mat-stepper>
