<div *ngIf="pageData" class="main">
  <div class="main__Gallery">
    <div class="main__Gallery__Video-Container">
      <video
        autoplay="true"
        [muted]="true"
        [loop]="true"
        [src]="pageData?.welcomeVid"
      ></video>
    </div>
  </div>
  <div class="main__Customer-Form">
    <div>
      <h1 class="contact-us">1. Choose Our Team Today!</h1>
      <div class="main__Customer-Form__Card-Vid">
        <video
          autoplay="true"
          [muted]="true"
          [loop]="true"
          [src]="pageData?.mobileVid"
        ></video>
      </div>
    </div>
    <div class="main__Customer-Form__Card">
      <h1 class="main__Customer-Form__Card__Title">
        2. Choose At Least One Service Below:
      </h1>
      <div class="service-selected">{{serviceSelection}}</div>
      <h4 class="main__Customer-Form__Card__Content">
        To optimize your experience and personalize our services according to
        your preferences, we kindly ask you to choose at least one or more items
        from the service categories provided below. This will allow us to gain a
        deeper understanding of your interests and serve you more effectively.
      </h4>
      <div class="cmj-btn">
        <cmj-button btnText="Website Development"></cmj-button>
      </div>
      <div class="cmj-btn">
        <cmj-button btnText="Mobile Development"></cmj-button>
      </div>
      <div class="cmj-btn">
        <cmj-button btnText="Software Development"></cmj-button>
      </div>

    </div>

    <div>
      <h1 class="contact-us">3. Customer details needed!</h1>
      <cmj-stepper [followUsVideo]="pageData?.followVid"></cmj-stepper>
    </div>
  </div>
  <div class="main__Top">
    <div class="main__Top__Overlay-Top"></div>
    <div class="main__Top__Img-Container-1">
      <img
        *ngFor="let img of shuffle(pageData?.portfolioImgs)"
        [src]="img"
        alt=""
      />
    </div>
    <div class="main__Top__Img-Container-2">
      <img
        *ngFor="let img of shuffle(pageData?.portfolioImgs)"
        [src]="img"
        alt=""
      />
    </div>
    <div class="main__Top__Img-Container-3">
      <img
        *ngFor="let img of shuffle(pageData?.portfolioImgs)"
        [src]="img"
        alt=""
      />
    </div>
    <div class="main__Top__Img-Container-4">
      <img
        *ngFor="let img of shuffle(pageData?.portfolioImgs)"
        [src]="img"
        alt=""
      />
    </div>

    <div class="main__Top__Title">
      <h3>
        At CMJ Software, we make it our mission to design, develop and evolve
        your ideas. From software engineering to design thinking, our
        experienced team is driven by commitment to the ultimate customer
        experience. We’re passionate about creating effective solutions that
        align with your unique needs and business goals. Let us help bring your
        vision to life – see below!
      </h3>

      <svg
        viewBox="0 0 24 24"
        stroke-width="2.17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#ffc917"
      >
        <path
          d="M6 13l6 6 6-6M6 5l6 6 6-6"
          stroke="#ffc917"
          stroke-width="2.17"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </div>

    <div class="main__Top__Cards-Container-2">
      <div class="section-title">
        <div class="section-title_our">See Our Mobile Development</div>
        <div class="section-title_svg">
          <svg height="0" width="0" viewBox="0 0 64 64" class="absolute">
            <defs class="s-xJBuHA073rTt" xmlns="http://www.w3.org/2000/svg">
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="b"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#973BED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#007CFF"
                  offset="1"
                ></stop>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="0"
                x2="0"
                y1="64"
                x1="0"
                id="c"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#FFC800"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#F0F"
                  offset="1"
                ></stop>
                <animateTransform
                  repeatCount="indefinite"
                  keySplines=".42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
                  keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                  dur="8s"
                  values="0 32 32;-270 32 32;-270 32 32;-540 32 32;-540 32 32;-810 32 32;-810 32 32;-1080 32 32;-1080 32 32"
                  type="rotate"
                  attributeName="gradientTransform"
                ></animateTransform>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="d"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#00E0ED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#00DA72"
                  offset="1"
                ></stop>
              </linearGradient>
            </defs>
          </svg>
          <!-- P -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="d"
              pathLength="360"
              d="M0,96A64.059,64.059,0,0,1,64,32h96a160,160,0,0,1,0,320H64v96a32,32,0,0,1-64,0V96ZM64,288h96a96,96,0,0,0,0-192H64Z"
            />
          </svg>
          <!-- R -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V288 448c0 17.7 14.3 32 32 32s32-14.3 32-32V320h95.3L261.8 466.4c10.1 14.5 30.1 18 44.6 7.9s18-30.1 7.9-44.6L230.1 309.5C282.8 288.1 320 236.4 320 176c0-79.5-64.5-144-144-144H64zM176 256H64V96H176c44.2 0 80 35.8 80 80s-35.8 80-80 80z"
            />
          </svg>
          <!-- I -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="y"
              pathLength="360"
              d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96h96V416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H192V96h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H160 32z"
            />
          </svg>
          <!-- C -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="d"
              pathLength="360"
              d="M329.1 142.9c-62.5-62.5-155.8-62.5-218.3 0s-62.5 163.8 0 226.3s155.8 62.5 218.3 0c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3c-87.5 87.5-221.3 87.5-308.8 0s-87.5-229.3 0-316.8s221.3-87.5 308.8 0c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0z"
            />
          </svg>
          <!-- E -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V288H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V96H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64z"
            />
          </svg>

          <!-- S -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#d)"
              class="dash"
              id="y"
              pathLength="360"
              d="M99.1 105.4C79 114 68.2 127.2 65.2 144.8c-2.4 14.1-.7 23.2 2 29.4c2.8 6.3 7.9 12.4 16.7 18.6c19.2 13.4 48.3 22.1 84.9 32.5c1 .3 1.9 .6 2.9 .8c32.7 9.3 72 20.6 100.9 40.7c15.7 10.9 29.9 25.5 38.6 45.1c8.8 19.8 10.8 42 6.6 66.3c-7.3 42.5-35.3 71.7-71.8 87.3c-35.4 15.2-79.1 17.9-123.7 10.9l-.2 0 0 0c-24-3.9-62.7-17.1-87.6-25.6c-4.8-1.7-9.2-3.1-12.8-4.3C5.1 440.8-3.9 422.7 1.6 405.9s23.7-25.8 40.5-20.3c4.9 1.6 10.2 3.4 15.9 5.4c25.4 8.6 56.4 19.2 74.4 22.1c36.8 5.7 67.5 2.5 88.5-6.5c20.1-8.6 30.8-21.8 33.9-39.4c2.4-14.1 .7-23.2-2-29.4c-2.8-6.3-7.9-12.4-16.7-18.6c-19.2-13.4-48.3-22.1-84.9-32.5c-1-.3-1.9-.6-2.9-.8c-32.7-9.3-72-20.6-100.9-40.7c-15.7-10.9-29.9-25.5-38.6-45.1c-8.8-19.8-10.8-42-6.6-66.3l31.5 5.5L2.1 133.9C9.4 91.4 37.4 62.2 73.9 46.6c35.4-15.2 79.1-17.9 123.7-10.9c13 2 52.4 9.6 66.6 13.4c17.1 4.5 27.2 22.1 22.7 39.2s-22.1 27.2-39.2 22.7c-11.2-3-48.1-10.2-60.1-12l4.9-31.5-4.9 31.5c-36.9-5.8-67.5-2.5-88.6 6.5z"
            />
          </svg>
        </div>
      </div>
      <div class="main__cards cards">
        <div class="cards__inner">
          <div
            *ngFor="let card of pageData?.mobileDevelopment"
            class="cards__card card"
          >
            <h2 class="card__heading">{{ card?.title }}</h2>
            <p class="card__price">{{ card?.price }}</p>
            <ul
              *ngFor="let item of card?.included"
              role="list"
              class="card__bullets flow"
            >
              <li>{{ item }}</li>
            </ul>
            <cmj-button btnText="Get Started"></cmj-button>
          </div>
        </div>

        <div class="overlay cards__inner"></div>
      </div>
    </div>

    <div class="main__Top__Cards-Container">
      <div class="section-title">
        <div class="section-title_our">See Our Web Development</div>
        <div class="section-title_svg">
          <svg height="0" width="0" viewBox="0 0 64 64" class="absolute">
            <defs class="s-xJBuHA073rTt" xmlns="http://www.w3.org/2000/svg">
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="b"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#973BED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#007CFF"
                  offset="1"
                ></stop>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="0"
                x2="0"
                y1="64"
                x1="0"
                id="c"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#FFC800"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#F0F"
                  offset="1"
                ></stop>
                <animateTransform
                  repeatCount="indefinite"
                  keySplines=".42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
                  keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                  dur="8s"
                  values="0 32 32;-270 32 32;-270 32 32;-540 32 32;-540 32 32;-810 32 32;-810 32 32;-1080 32 32;-1080 32 32"
                  type="rotate"
                  attributeName="gradientTransform"
                ></animateTransform>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="d"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#00E0ED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#00DA72"
                  offset="1"
                ></stop>
              </linearGradient>
            </defs>
          </svg>
          <!-- P -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="d"
              pathLength="360"
              d="M0,96A64.059,64.059,0,0,1,64,32h96a160,160,0,0,1,0,320H64v96a32,32,0,0,1-64,0V96ZM64,288h96a96,96,0,0,0,0-192H64Z"
            />
          </svg>
          <!-- R -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V288 448c0 17.7 14.3 32 32 32s32-14.3 32-32V320h95.3L261.8 466.4c10.1 14.5 30.1 18 44.6 7.9s18-30.1 7.9-44.6L230.1 309.5C282.8 288.1 320 236.4 320 176c0-79.5-64.5-144-144-144H64zM176 256H64V96H176c44.2 0 80 35.8 80 80s-35.8 80-80 80z"
            />
          </svg>
          <!-- I -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="y"
              pathLength="360"
              d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96h96V416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H192V96h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H160 32z"
            />
          </svg>
          <!-- C -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="d"
              pathLength="360"
              d="M329.1 142.9c-62.5-62.5-155.8-62.5-218.3 0s-62.5 163.8 0 226.3s155.8 62.5 218.3 0c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3c-87.5 87.5-221.3 87.5-308.8 0s-87.5-229.3 0-316.8s221.3-87.5 308.8 0c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0z"
            />
          </svg>
          <!-- E -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V288H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V96H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64z"
            />
          </svg>

          <!-- S -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#c)"
              class="dash"
              id="y"
              pathLength="360"
              d="M99.1 105.4C79 114 68.2 127.2 65.2 144.8c-2.4 14.1-.7 23.2 2 29.4c2.8 6.3 7.9 12.4 16.7 18.6c19.2 13.4 48.3 22.1 84.9 32.5c1 .3 1.9 .6 2.9 .8c32.7 9.3 72 20.6 100.9 40.7c15.7 10.9 29.9 25.5 38.6 45.1c8.8 19.8 10.8 42 6.6 66.3c-7.3 42.5-35.3 71.7-71.8 87.3c-35.4 15.2-79.1 17.9-123.7 10.9l-.2 0 0 0c-24-3.9-62.7-17.1-87.6-25.6c-4.8-1.7-9.2-3.1-12.8-4.3C5.1 440.8-3.9 422.7 1.6 405.9s23.7-25.8 40.5-20.3c4.9 1.6 10.2 3.4 15.9 5.4c25.4 8.6 56.4 19.2 74.4 22.1c36.8 5.7 67.5 2.5 88.5-6.5c20.1-8.6 30.8-21.8 33.9-39.4c2.4-14.1 .7-23.2-2-29.4c-2.8-6.3-7.9-12.4-16.7-18.6c-19.2-13.4-48.3-22.1-84.9-32.5c-1-.3-1.9-.6-2.9-.8c-32.7-9.3-72-20.6-100.9-40.7c-15.7-10.9-29.9-25.5-38.6-45.1c-8.8-19.8-10.8-42-6.6-66.3l31.5 5.5L2.1 133.9C9.4 91.4 37.4 62.2 73.9 46.6c35.4-15.2 79.1-17.9 123.7-10.9c13 2 52.4 9.6 66.6 13.4c17.1 4.5 27.2 22.1 22.7 39.2s-22.1 27.2-39.2 22.7c-11.2-3-48.1-10.2-60.1-12l4.9-31.5-4.9 31.5c-36.9-5.8-67.5-2.5-88.6 6.5z"
            />
          </svg>
        </div>
      </div>
      <div class="main__cards cards">
        <div class="cards__inner">
          <div
            *ngFor="let card of pageData?.webDevelopment"
            class="cards__card card"
          >
            <h2 class="card__heading">{{ card?.title }}</h2>
            <p class="card__price">{{ card?.price }}</p>
            <ul
              *ngFor="let item of card?.included"
              role="list"
              class="card__bullets flow"
            >
              <li>{{ item }}</li>
            </ul>
            <cmj-button btnText="Get Started"></cmj-button>
          </div>
        </div>

        <div class="overlay cards__inner"></div>
      </div>
    </div>

    <div class="main__Top__Cards-Container-3">
      <div class="section-title">
        <div class="section-title_our">See Our Software Development</div>
        <div class="section-title_svg">
          <svg height="0" width="0" viewBox="0 0 64 64" class="absolute">
            <defs class="s-xJBuHA073rTt" xmlns="http://www.w3.org/2000/svg">
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="b"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#973BED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#007CFF"
                  offset="1"
                ></stop>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="0"
                x2="0"
                y1="64"
                x1="0"
                id="c"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#FFC800"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#F0F"
                  offset="1"
                ></stop>
                <animateTransform
                  repeatCount="indefinite"
                  keySplines=".42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1;.42,0,.58,1"
                  keyTimes="0; 0.125; 0.25; 0.375; 0.5; 0.625; 0.75; 0.875; 1"
                  dur="8s"
                  values="0 32 32;-270 32 32;-270 32 32;-540 32 32;-540 32 32;-810 32 32;-810 32 32;-1080 32 32;-1080 32 32"
                  type="rotate"
                  attributeName="gradientTransform"
                ></animateTransform>
              </linearGradient>
              <linearGradient
                class="s-xJBuHA073rTt"
                gradientUnits="userSpaceOnUse"
                y2="2"
                x2="0"
                y1="62"
                x1="0"
                id="d"
              >
                <stop class="s-xJBuHA073rTt" stop-color="#00E0ED"></stop>
                <stop
                  class="s-xJBuHA073rTt"
                  stop-color="#00DA72"
                  offset="1"
                ></stop>
              </linearGradient>
            </defs>
          </svg>
          <!-- P -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="d"
              pathLength="360"
              d="M0,96A64.059,64.059,0,0,1,64,32h96a160,160,0,0,1,0,320H64v96a32,32,0,0,1-64,0V96ZM64,288h96a96,96,0,0,0,0-192H64Z"
            />
          </svg>
          <!-- R -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V288 448c0 17.7 14.3 32 32 32s32-14.3 32-32V320h95.3L261.8 466.4c10.1 14.5 30.1 18 44.6 7.9s18-30.1 7.9-44.6L230.1 309.5C282.8 288.1 320 236.4 320 176c0-79.5-64.5-144-144-144H64zM176 256H64V96H176c44.2 0 80 35.8 80 80s-35.8 80-80 80z"
            />
          </svg>
          <!-- I -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="y"
              pathLength="360"
              d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96h96V416H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H192V96h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H160 32z"
            />
          </svg>
          <!-- C -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="d"
              pathLength="360"
              d="M329.1 142.9c-62.5-62.5-155.8-62.5-218.3 0s-62.5 163.8 0 226.3s155.8 62.5 218.3 0c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3c-87.5 87.5-221.3 87.5-308.8 0s-87.5-229.3 0-316.8s221.3-87.5 308.8 0c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0z"
            />
          </svg>
          <!-- E -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="y"
              pathLength="360"
              d="M64 32C28.7 32 0 60.7 0 96V256 416c0 35.3 28.7 64 64 64H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V288H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V96H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H64z"
            />
          </svg>

          <!-- S -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style="--rotation-duration: 0ms; --rotation-direction: normal"
            viewBox="0 0 320 512"
            height="64"
            width="64"
            class="inline-block"
          >
            <path
              stroke-linejoin="round"
              stroke-linecap="round"
              stroke-width="10"
              stroke="url(#b)"
              class="dash"
              id="y"
              pathLength="360"
              d="M99.1 105.4C79 114 68.2 127.2 65.2 144.8c-2.4 14.1-.7 23.2 2 29.4c2.8 6.3 7.9 12.4 16.7 18.6c19.2 13.4 48.3 22.1 84.9 32.5c1 .3 1.9 .6 2.9 .8c32.7 9.3 72 20.6 100.9 40.7c15.7 10.9 29.9 25.5 38.6 45.1c8.8 19.8 10.8 42 6.6 66.3c-7.3 42.5-35.3 71.7-71.8 87.3c-35.4 15.2-79.1 17.9-123.7 10.9l-.2 0 0 0c-24-3.9-62.7-17.1-87.6-25.6c-4.8-1.7-9.2-3.1-12.8-4.3C5.1 440.8-3.9 422.7 1.6 405.9s23.7-25.8 40.5-20.3c4.9 1.6 10.2 3.4 15.9 5.4c25.4 8.6 56.4 19.2 74.4 22.1c36.8 5.7 67.5 2.5 88.5-6.5c20.1-8.6 30.8-21.8 33.9-39.4c2.4-14.1 .7-23.2-2-29.4c-2.8-6.3-7.9-12.4-16.7-18.6c-19.2-13.4-48.3-22.1-84.9-32.5c-1-.3-1.9-.6-2.9-.8c-32.7-9.3-72-20.6-100.9-40.7c-15.7-10.9-29.9-25.5-38.6-45.1c-8.8-19.8-10.8-42-6.6-66.3l31.5 5.5L2.1 133.9C9.4 91.4 37.4 62.2 73.9 46.6c35.4-15.2 79.1-17.9 123.7-10.9c13 2 52.4 9.6 66.6 13.4c17.1 4.5 27.2 22.1 22.7 39.2s-22.1 27.2-39.2 22.7c-11.2-3-48.1-10.2-60.1-12l4.9-31.5-4.9 31.5c-36.9-5.8-67.5-2.5-88.6 6.5z"
            />
          </svg>
        </div>
      </div>
      <div class="main__cards cards">
        <div class="cards__inner">
          <div
            *ngFor="let card of pageData?.softwareDevelopment"
            class="cards__card card"
          >
            <h2 class="card__heading">{{ card?.title }}</h2>
            <p class="card__price">{{ card?.price }}</p>
            <ul
              *ngFor="let item of card?.included"
              role="list"
              class="card__bullets flow"
            >
              <li>{{ item }}</li>
            </ul>
            <cmj-button btnText="Get Started"></cmj-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<cmj-banner></cmj-banner>
