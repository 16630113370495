import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app/app.service';

@Component({
  selector: 'cmj-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  public isBannerShowing = false;
  public bannerMsg: any;
  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appService.showBanner.subscribe((res) => {
      this.isBannerShowing = res.show;
      this.bannerMsg = res.msg;
    })
  }
}
