import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/services/app/app.service';

@Component({
  selector: 'cmj-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public btnText = 'Button';

  constructor(private appService: AppService) { }

  handleSelection(selection: any) {
    this.appService.serviceSelection.next(selection);
    this.appService.setLocalStorage('serviceSelected', selection);
  }
}
