import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public serviceSelection: BehaviorSubject<any> = new BehaviorSubject('');
  public isLoading: BehaviorSubject<any> = new BehaviorSubject('');
  public showBanner: BehaviorSubject<any> = new BehaviorSubject({});
  public appID = '_cmj_';

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  triggerLoader() {
    this.isLoading.next(true);
  }

  retrieve(r: any) {
    return this.http.get(r);
  }

  send(u: any, d: any) {
    return this.http.post(u, d);
  }

  getLocalStorage(k: any) {
    return JSON.parse(localStorage.getItem(k) as string);
  }

  setLocalStorage(k: any, v: any) {
    localStorage.setItem(k, JSON.stringify(v));
  }

  deleteLocalStorage(k: any) {
    localStorage.removeItem(k);
  }

  setCookieAndShowBanner(val: boolean, cookie: any) {
    this.setCookie(this.appID, cookie);
    this.showBanner.next({
      msg: 'Please Wait!!',
      show: val,
    });
    window.location.reload();
  }

  checkCookieAndSetStorage(url: any) {
    const cookie = this.getCookie(this.appID) as any;
    console.log('cookie', typeof cookie);
    if (cookie && cookie !== 'false') {
      return JSON.parse(localStorage.getItem(this.appID) as string) !== null
        ? JSON.parse(localStorage.getItem(this.appID) as string)
        : this.setCookieAndShowBanner(true, false);
    } else {
      let pageData: any;
      this.retrieve(`api/${url}`).subscribe((res: any) => {
        console.log('res', res);
        if (res?.main) this.setCookie(this.appID, 'true');
        if (res?.main)
          localStorage.setItem(this.appID, JSON.stringify(res.main));
        pageData = res.main;
        this.setCookieAndShowBanner(true, true);
      });

      return pageData
        ? JSON.parse(pageData as string)
        : JSON.parse(localStorage.getItem(this.appID) as string);
    }
  }

  getCookie(key: string) {
    return this.cookieService.get(key);
  }

  setCookie(key: string, value: string) {
    return this.cookieService.put(key, value);
  }

  deleteCookie(key: string) {
    return this.cookieService.remove(key);
  }
}
